<template>
  <div>
    <div
      style="height: calc(100vh - 145px); overflow-y: auto; overflow-x: hidden"
    >
      <ListCategory v-if="isActiveTab('category-list')" />
      <UpdateCategoryExcel v-if="isActiveTab('update-category-excel')" />
    </div>
    <v-divider class="mt-2"></v-divider>
    <div
      class="pt-2 pb-4 pb-md-2 white-space-nowrap overflow-x-auto overflow-y-hidden"
    >
      <div>
        <v-btn
          :outlined="!isActiveTab('category-list')"
          color="primary"
          small
          class="mr-2"
          @click="changeTab('category-list')"
        >
          {{ $t("labels.category_list") }}
        </v-btn>
        <v-btn
          :outlined="!isActiveTab('update-category-excel')"
          color="primary"
          small
          class="mr-2"
          @click="changeTab('update-category-excel')"
        >
          {{ $t("labels.update_goods_category") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Category",
  components: {
    ListCategory: () => import("@/components/goods/ListCategory"),
    UpdateCategoryExcel: () => import("@/components/goods/UpdateCategoryExcel"),
  },
  data: () => ({
    isLoading: false,
    tab: "category-list",
  }),
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
    isActiveTab(tab) {
      return this.tab === tab;
    },
  },
};
</script>

<style lang="scss"></style>
